export const exercise_items = [
  // {
  //   key: 'seated_idle',
  //   leftSideIndx: 0,
  //   rightSideIndx:0,
  //   label: 'Seated Idle',
  //   label_id: 'app.exercise.seated_idle.title',
  //   description_id: 'app.exercise.seated_idle.description',
  //   explanation : 'Be sure you are in correct pose, you can start with your right or left hand'
  // },
  {
    idx: 0,
    key: 'Shoulder_Warmup',
    leftSideIndx: 2,
    rightSideIndx: 1,
    label: 'Shoulder Warm-Up',
    label_id: 'app.exercise.Shoulder_Warmup.title',
    description_id: 'app.exercise.Shoulder_Warmup.description',
    explanation: 'The practice that prepares the body for activity.',
    benefit1: ' Reducing stress, tension and improving circulation in the shoulder joint and muscles.',
    benefit2: 'Helps to improve the range of motion of the muscles, and reduce heaviness and stiffness due to long hours sitting at the desk.'
  },
  {
    idx: 1,
    key: 'Seated_Side_Stretch',
    leftSideIndx: 4,
    rightSideIndx: 3,
    label: 'Seated Side Stretch',
    label_id: 'app.exercise.Seated_Side_Stretch.title',
    description_id: 'app.exercise.Seated_Side_Stretch.description',
    explanation: 'The practice of stretching the arm to the other side while actively using one side of the torso muscles like the shoulders, upper back, and chest.',
    benefit1: 'A quick way to relax the upper body while taking a break from work and reducing the stiffness due to long hours of bending forward while seated in a bad posture.',
    benefit2: 'Helps to open the shoulders and the neck when the arms are stretched to the opposite way, but with strong stability with the support of the chair, the range of motion is far better as you stretch.'
  },
  {
    idx: 2,
    key: 'Upward_Hand_Stretch',
    leftSideIndx: 6,
    rightSideIndx: 5,
    label: 'Upward Hand Stretch',
    label_id: 'app.exercise.Upward_Hand_Stretch.title',
    description_id: 'app.exercise.Upward_Hand_Stretch.description',
    explanation: 'The practice that provides the muscles flexibility and durability.',
    benefit1: ' Improves the range of motion in the upper body, specifically around the shoulder and elbow joints.',
    benefit2: ' Enhances the blood flow and encourages the blood flow throughout the muscles in your upper arms, alleviating soreness.'
  },
  {
    idx: 3,
    key: 'Triceps_Stretch',
    leftSideIndx: 7,
    rightSideIndx: 8,
    label: 'Triceps Stretch',
    label_id: 'app.exercise.Triceps_Stretch.title',
    description_id: 'app.exercise.Triceps_Stretch.description',
    explanation: 'The practice that provides the muscles flexibility and durability.',
    benefit1: ' Improves the range of motion in the upper body, specifically around the shoulder and elbow joints.',
    benefit2: ' Enhances the blood flow and encourages the blood flow throughout the muscles in your upper arms, alleviating soreness.'
  },
  {
    idx: 4,
    key: 'Neck_Stretch',
    leftSideIndx: 9,
    rightSideIndx: 10,
    label: 'Neck Stretch',
    label_id: 'app.exercise.Neck_Stretch.title',
    description_id: 'app.exercise.Neck_Stretch.description',
    explanation: 'The practice can expand the elasticity in the cervical spine area.',
    benefit1: ' Increase flexibility, improves range of motion, and reduces muscle soreness.',
    benefit2: ' Helps to remove tension, muscle knots, and tightness in the neck.'
  },
  {
    idx: 5,
    key: 'All_Exercise',
    leftSideIndx: 9,
    rightSideIndx: 10,
    label: "Let's do them all",
    label_id: 'app.exercise.All_Exercise.title',
 
  },
  
]
