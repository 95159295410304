import * as React from 'react';
import PropTypes from "prop-types";
import ProgressBar from "@ramonak/react-progress-bar";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function NextExerciseSelectingModal(props) {
  const { onRetry, onNext, onExit, progress, showNextButton } = props;

  const handleOnRetry = () => {
    typeof onRetry === 'function' && onRetry();
  };

  const handleOnNext = () => {
    typeof onNext === 'function' && onNext();
  };

  const handleOnExit = () => {
    typeof onExit === 'function' && onExit();
  };

  return (
    <Dialog
      open={true}
      onClose={handleOnExit}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          Exercise completed! You can choose:
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{display:'flex', justifyContent: 'center'}}>
        <Button sx={{ color: '#00252e' }} autoFocus onClick={handleOnRetry}>
        Retry
        </Button>
        {showNextButton &&
          <div style={{ position: 'relative' }}>
            <Button sx={{ color: '#00252e' }} autoFocus onClick={handleOnNext}>
            Next
            </Button>
          </div>
        }
        <Button sx={{ color: '#00252e' }} autoFocus onClick={handleOnExit}>
        Exit
        </Button>
      </DialogActions>
      <div className="ButtonProgressBar">
        <ProgressBar
          completed={progress}
          maxCompleted={90}
          bgColor={"#257362"}
          // className="wrapper"
          barContainerClassName="barContainerClass"
          // completedClassName="barCompleted"
          isLabelVisible={false}
          height="10px"
          transitionDuration={0.9}
        />
      </div>
    </Dialog>
  );
}
NextExerciseSelectingModal.prototype = {
  onRetry: PropTypes.func,
  onNext: PropTypes.func,
  onExit: PropTypes.func,
  progress: PropTypes.number.isRequired,
  showNextButton: PropTypes.bool.isRequired,
}
export default NextExerciseSelectingModal;