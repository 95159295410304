import React from 'react';
import { useNavigate } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';


import {
  Grid, Card, CardActionArea, CardContent, CardMedia, Typography
} from '@mui/material';

import { exercise_items } from "../constants/ExerciseConstant";
import Exercise0Image from "../assets/images/exercise-0.JPG";
import ShoulderWarmup from "../assets/images/Shoulder_Warmup.JPG";
import Seated_Side from "../assets/images/Seated_Side.JPG";
import Hand_Up from "../assets/images/Hand_Up.JPG";
import Triceps from "../assets/images/Triceps.JPG";
import Neck_Stretch from "../assets/images/Neck_Stretch.JPG";
import Logo from "../assets/images/app_logo.JPG";
import Footer from "../components/Footer";
import Header from "../components/Header";
import '../style/Home.css';


function Home(props) {
  const { intl } = props;
  const navigate = useNavigate();
  const _renderExerciseCard = (exerciseItem) => {
    let exerciseImage = null;
    if (exerciseItem.key === "seated_idle")
      exerciseImage = Exercise0Image;
    if (exerciseItem.key === "Seated_Side_Stretch")
      exerciseImage = Seated_Side;
    if (exerciseItem.key === "Shoulder_Warmup")
      exerciseImage = ShoulderWarmup;
    if (exerciseItem.key === "Upward_Hand_Stretch")
      exerciseImage = Hand_Up;
    if (exerciseItem.key === "Triceps_Stretch")
      exerciseImage = Triceps;
    if (exerciseItem.key === "Neck_Stretch")
      exerciseImage = Neck_Stretch;
    if (exerciseItem.key === "All_Exercise")
      exerciseImage = Logo;
    return (
      <Card className='CardAreaContainer'
        onClick={() => {
          if (exerciseItem.key === "All_Exercise") {
            navigate(`/tutorial`)
          }
          else {
            navigate(`/exercise/${exerciseItem.key}`)
          }

        }}>
        <CardActionArea>
          <CardMedia
          style={{ width: '100%', height: '270px', objectFit: 'cover',
          '@media (maxWidth: 500px)': {
            height: '190px',
          }, }}
            image={exerciseImage}
            // title={intl.formatMessage({ id: exerciseItem.label_id })}
          />
          <CardContent className='CardContentStyle'>
            <Typography gutterBottom variant="h6"
              style={{
                color: '#1C5454',
                fontWeight: 'bold',
                fontSize: '1rem', 
              }}>
              <FormattedMessage id={exerciseItem.label_id} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <div className='RootHome'>
      <div className='HeaderContainer'>
        <Header />
      </div>
      <div className='PageContainer'>
     
        <div className='CardsContainer'>
          <div className='TextStyleExercise'>  Welcome to the FitU2! Let's Get Started with Your Fitness Journey! </div>
          <Grid className='GridStyle' container >
            {exercise_items.map((exercise_item, index) => {
              return (
                <Grid key={exercise_item.key} item xs={12} sm={6} md={4} lg={3}
                  style={{ margin: '20px' }}>
                  {_renderExerciseCard(exercise_item)}
                </Grid>)
            })}
          </Grid>
        </div>
      </div>
      <div className='FooterContainer'>
        <Footer />
      </div>
    </div>
  );
}

export default injectIntl(Home);
