import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from "react-router-dom";
function CameraAccessDeniedModal(props) {
    const navigate = useNavigate();

    const handleClickOpen = () => {
        navigate(`/`)
      };
    return (
          <Dialog
            open={true}
            onClose={handleClickOpen}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
              Your device doesn't have access to the camera. Please check your browser settings to enable camera access for this site.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{color:'#00252e'}} autoFocus onClick={handleClickOpen}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
      );
}

export default CameraAccessDeniedModal;