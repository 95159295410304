import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import { injectIntl } from 'react-intl';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Webcam from "react-webcam";
import * as cam from "@mediapipe/camera_utils";
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { Pose, POSE_CONNECTIONS } from '@mediapipe/pose';


import { w3cwebsocket as W3CWebSocket } from "websocket";

import '../style/SingleExercise.css';
import { exercise_config_variables } from "../constants/SettingConstant";
import WaitingModal from "./WaitingModal";
import ContinoueExercisingModal from "./ContinoueExercisingModal";
import NextExerciseSelectingModal from "./NextExerciseSelectingModal";
import notifyingSound from "../assets/sounds/3-2-1-start (4 seconds).mp3"


//left shoulder, right shoulder, left elbow, right elbow, left wrist, right wrist, left hip, right hip, nose
const joint_idx = [11, 12, 13, 14, 15, 16, 23, 24];
let side = '';
let leftSide = false;
let rightSide = false;
let camera = null;
let stopAtTheMiddleOfExercise = false;
const API_URL = exercise_config_variables.API_URL;
const WEBSOCKET_URL = exercise_config_variables.WEBSOCKET_URL;
const BATCH_SIZE = exercise_config_variables.BATCH_SIZE;
let SAVE_VIDEO = exercise_config_variables.SAVE_VIDEO;
const NOT_EXERCISING_TIMEOUT = exercise_config_variables.NOT_EXERCISING_TIMEOUT;

let batch_data = [];
let detection_result = '';
let exercise_instance_id = 0;
let images_list = [];
let showWaitingModal_ = false;
let showNextModal_ = false;
let userMetaData = {};
let userLocation = '';

function SequentialExercises(props) {

  const [feedbackText, setFeedbackText] = useState("Ensure you are positioned correctly within the camera frame.\nThen, wait for the system to detect your skeleton")
  const [poseDetected, setPoseDetected] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [timer, setTimer] = useState("00:00:10");
  const [startingTime, setStartingTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [startVideo, setStartVideo] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [sessionCompleted, setSessionCompleted] = useState(false);
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showNextExerciseModal, setShowNextExerciseModal] = useState(false);
  const [modalOverlay, setModalOverlay] = useState(false);
  const [showContinoueExersingModal, setShowContinoueExercisesModal] = useState(false);
  const [timerForAutomatedNext, setTimerForAutomatedNext] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showNextButton, setShowNextButton] = useState(true);
  const [videoWidth, setVideoWidth] = useState(null);
  const [videoHeight, setVideoHeight] = useState(null);
  const [orientationMode, setOrientationMode] = useState(window.screen.orientation.type); //portrait-primary' 'landscape-primary'


  let cameraType = '';
  let exerciseId = props.exerciseId;
  let exerciseIndex = props.exerciseIndex;
  let leftSideIndx = props.exerciseleftIdx;
  let rightSideIndx = props.exerciseRightIdx;
  let sessionId = props.sessionId;
  let exerciseLength = props.exerciseLength;


  const IMAGES = {
    seated_idle: require('../assets/images/exercise-0.JPG'),
    Seated_Side_Stretch: require('../assets/images/Seated_Side.JPG'),
    Shoulder_Warmup: require("../assets/images/Shoulder_Warmup.JPG"),
    Upward_Hand_Stretch: require("../assets/images/Hand_Up.JPG"),
    Triceps_Stretch: require("../assets/images/Triceps.JPG"),
    Neck_Stretch: require("../assets/images/Neck_Stretch.JPG"),
  }
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const socketRef = useRef();
  let audio = useRef(new Audio(notifyingSound));
  const lastActivityTimeRef = useRef(new Date(Date()).getTime());
  let connection_color = 'black';
  
  useEffect(() => {
    const updateVideoDimensions = () => {
      setVideoHeight(window.innerHeight);
      setVideoWidth(window.innerWidth);
      console.log("window inner height: ", window.innerHeight)
      console.log("window inner width: ", window.innerWidth)
    };

    const handleOrientationChange = (event) => {
      setOrientationMode(event.target.screen.orientation.type);
    };


    // Run the effect immediately and every time the orientation changes
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', updateVideoDimensions);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('resize', updateVideoDimensions);
    };
  }, []);



  useEffect(() => {
    // setVideoHeight(window.innerHeight);
    // setVideoWidth(window.innerWidth);

    startCam();
  }, []);

  useEffect(() => {
    // Clean up WebSocket connection on component unmount
    return () => {
      socketRef && socketRef.current && socketRef.current.close();
    };

  }, []);

  useEffect(() => {
    const onResults = (results) => {
      if (!results.poseLandmarks || webcamRef.current == null) {
        setPoseDetected(false);
        setFeedbackText("Ensure you are positioned correctly within the camera frame.\nThen, wait for the system to detect your skeleton");
        return;
      }
      else {
        setPoseDetected(true);
      }

      const now = new Date(Date()).getTime();
      if ((now - lastActivityTimeRef.current) / 1000 > NOT_EXERCISING_TIMEOUT && side === '') {
        console.log("you are not start exercing yet");
        setShowContinoueExercisesModal(true);
        setModalOverlay(true);
        return
      }


      const _videoWidth = webcamRef.current.video.videoWidth;
      const _videoHeight = webcamRef.current.video.videoHeight;
      // const _videoWidth = videoWidth;
      // const _videoHeight = videoHeight;
      // console.log("video width:", _videoWidth, "video height:", _videoHeight);
      // console.log("video width (screen):", videoWidth, "video height (screen):", videoHeight);


      // Set canvas width
      canvasRef.current.width = _videoWidth;
      canvasRef.current.height = _videoHeight;
      const canvasElement = canvasRef.current;
      const canvasCtx = canvasElement.getContext("2d")
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasCtx.drawImage(
        results.image,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      const landmarks = [...results.poseLandmarks];
      drawConnectors(canvasCtx,
        results.poseLandmarks, POSE_CONNECTIONS,
        { color: connection_color, lineWidth: 5 });
      // The dots are the landmarks
      drawLandmarks(canvasCtx, results.poseLandmarks,
        { color: 'white', lineWidth: 10, radius: 2 });
      canvasCtx.restore();
      let input = []
      let temp = landmarks.map((keypoint, idx) => {
        if (joint_idx.includes(idx)) {
          input.push(keypoint.x)
          input.push(keypoint.y)
          input.push(keypoint.z)
          input.push(keypoint.visibility)
        }
      });
      if (showWaitingModal_ || showNextModal_) {
        // connection_color = 'black';
        return
      }
      let text = "";
      let perc = 0;
      let exer_status = '';
      let exer_type = '';
      let sessionCompleted = false;
      let max_index = 0;
      batch_data.push(input);
      const image = webcamRef.current.getScreenshot();
      images_list.push(image);
      if (webcamRef.current !== null && batch_data.length === BATCH_SIZE) {
        batch_data = [];
        detection_exercise(input);
      }
      if (socketRef && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        max_index = detection_result['label'];
        perc = detection_result['score'];
        exer_status = detection_result['status'];
        setSuggestion(detection_result['suggestion']);
        exer_type = detection_result['type'];
        if (max_index === 0) {
          connection_color = 'red';
          /// start of the session
          if (side === '') {
            side = '';
            text = "Start Exercising";
            setFeedbackText(text);
            setPercentage(0);
            setTimer(("00:00:10"));
          }

          if (exer_type === 'one_sided') {
            /// session completed
            if (leftSide === true && side === 'left') {
              connection_color = 'black';
              text = "Session Completed";
              setFeedbackText(text);
              setPercentage(0);
              setTimer("00:00:00");
              sessionCompleted = true;
              setSessionCompleted(true);

              // stopCam();
            }
            /// at the middle of exer
            if (leftSide === false && side === 'left') {
              text = "You didn't complete your exercise.";
              audio.current.pause();
              audio.current.currentTime = 0;
              stopAtTheMiddleOfExercise = true;
              // setStopAtTheMiddleOfExercise(true);
              setFeedbackText(text);
              setStartingTime(new Date(Date()).getTime());
              setPercentage(0);
            }
          }
          if (exer_type === 'two_sided') {
            /// left completed
            if (leftSide === true && side === 'left' && rightSide === false) {
              text = "Left hand was done. Change your hand";
              setFeedbackText(text);
            }
            /// right completed
            if (leftSide === false && side === 'right' && rightSide === true) {
              text = "Right hand was done. Change your hand";
              setFeedbackText(text);
            }
            /// session completed
            if (leftSide === true && rightSide === true) {
              connection_color = 'black';
              text = "Session Completed";
              setFeedbackText(text);
              setPercentage(0);
              setTimer("00:00:00");
              sessionCompleted = true;
              setSessionCompleted(true);

              // stopCam();
            }
            /// at the middle of left exer
            if (leftSide === false && side === 'left') {
              text = "You didn't complete the exercise with your left hand";
              stopAtTheMiddleOfExercise = true;
              audio.current.pause();
              audio.current.currentTime = 0;
              setFeedbackText(text);
              setStartingTime(new Date(Date()).getTime());
              setPercentage(0);
            }
            /// at the middle of right exer
            if (rightSide === false && side === 'right') {
              text = "You didn't complete the exercise with your right hand";
              stopAtTheMiddleOfExercise = true;
              audio.current.pause();
              audio.current.currentTime = 0;
              setFeedbackText(text);
              setStartingTime(new Date(Date()).getTime());
              setPercentage(0);
            }
          }

        }
        else if (max_index === leftSideIndx) {
          if (leftSide === false) {
            if (exer_status === 'perfect') {
              connection_color = 'green';
            }
            else if (exer_status === 'acceptable') {
              connection_color = 'purple';
            }
            if (side !== 'left') {
              setStartingTime(new Date(Date()).getTime());
              side = 'left';
            }
            setCurrentTime(new Date(Date()).getTime());
            if (exerciseId === 'Upward_Hand_Stretch' || exerciseId === 'Shoulder_Warmup' || exerciseId === 'Triceps_Stretch') {
              text = "Ok! You're ready to start. Hold this pose for 10 seconds.";
            }
            else {
              text = "“Ok! Begin with your left hand. Maintain this pose for 10 seconds.";
            }
            setFeedbackText(text);
            // perc = predicted_prob.toFixed(2)* 100;
            setPercentage(perc);
          }
          else {
            if (!sessionCompleted) {
              if (exerciseId === 'Upward_Hand_Stretch' || exerciseId === 'Shoulder_Warmup' || exerciseId === 'Triceps_Stretch') {
                text = "Exercise was done.";
              }
              else {
                text = "Left hand was done.";
              }
              setFeedbackText(text);
            }
          }
        }
        else if (max_index === rightSideIndx) {
          if (rightSide === false) {
            if (exer_status === 'perfect') {
              connection_color = 'green';
            }
            else if (exer_status === 'acceptable') {
              connection_color = 'purple';
            }
            if (side !== 'right') {
              setStartingTime(new Date(Date()).getTime());
              side = 'right';
            }
            setCurrentTime(new Date(Date()).getTime());
            if (exerciseId === 'Upward_Hand_Stretch' || exerciseId === 'Shoulder_Warmup' || exerciseId === 'Triceps_Stretch') {
              text = "Ok! You're ready to start. Hold this pose for 10 seconds.";
            }
            else {
              text = "Ok! Begin with your right hand. Maintain this pose for 10 seconds.";
            } setFeedbackText(text);
            // perc = predicted_prob.toFixed(2)* 100;
            setPercentage(perc);
          }
          else {
            if (!sessionCompleted) {
              if (exerciseId === 'Upward_Hand_Stretch' || exerciseId === 'Shoulder_Warmup' || exerciseId === 'Triceps_Stretch') {
                text = "Exercise was done.";
              }
              else {
                text = "Right hand was done.";
              }
              setFeedbackText(text);
            }
          }
        }
      }
      else {
        connection_color = 'red';
        text = "Sorry!!! Something went wrong";
        setFeedbackText(text);
      }
    }
    if (startVideo) {

      const pose = new Pose({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
        },
      });
      pose.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      pose.onResults(onResults);
      if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null) {
        camera = new cam.Camera(webcamRef.current.video, {
          onFrame: async () => {
            await pose.send({ image: webcamRef.current.video });
          },
          // width: window.innerWidth,
          // height: window.innerHeight,
          width: videoWidth,
          height: videoHeight,
        });
        camera.start();
      }
    }
  }, [startVideo]);

  useEffect(() => {
    if (sessionCompleted) {
      connection_color = 'black';
      setModalOverlay(true);
      setShowWaitingModal(true);
      showWaitingModal_ = true;
      newRecordCreationRequest();
    }
  }, [sessionCompleted]);

  useEffect(() => {

    if (startVideo) {
      const timeDiff = (currentTime - startingTime) / 1000;
      if (side !== '') {
        // if (stopAtTheMiddleOfExercise === false) {
          if (timeDiff === 7) {
            console.log("isPlaying set True here");
            audio.current.play();
          }
        // }
        // else {
        //   audio.current.pause();
        //   audio.current.currentTime = 0;
        // }
        if (timeDiff < 11) {
          let t = 10 - timeDiff
          setTimer('00:00:' + t);
        }
        else {
          // console.log("TAMAAAAMMMMM");
          // audio.current.pause();
          // audio.current.currentTime = 0;
          if (side === 'left') {
            leftSide = true;
          }
          if (side === 'right') {
            rightSide = true;
          }
        }
      }
      else {
      }
    }
  }, [currentTime, startVideo]);

  useEffect(() => {
    if (showNextExerciseModal) {
      //handle automated next clicked
      if (exerciseIndex === 4) {
        setShowNextButton(false);
      }
      else {
        const timer = setTimeout(() => {
          emptyVariables(exerciseIndex + 1);
        }, 10000);
        setTimerForAutomatedNext(timer);
        console.log("timer::", timer)
      }
      const interval = setInterval(() => {
        setProgress(prev => prev + 10);
        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 1000);


      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }

  }, [showNextExerciseModal])

  const detection_exercise = async (joints_array) => {
    if (cameraType === '') {
      cameraType = await getCameraInfo();
      fetchData();
    }
    let userData = {
      "geolocation": userMetaData,
      "camera_type": cameraType,
    }
    let data_arra = {
      'joints': joints_array,
      'exer_name': exerciseId,
      'session_id': sessionId,
      'user_meta_data': userData
    }
    socketRef.current.send(JSON.stringify(data_arra));

  }

  const startCam = () => {
    console.log("start");
    detection_result = '';
    images_list = [];
    side = '';
    leftSide = false;
    rightSide = false;
    camera = null;
    connection_color = 'black';
    //clear timer for handling automated next clicked
    clearTimeout(timerForAutomatedNext);
    setShowNextExerciseModal(false);
    showNextModal_ = false;
    setShowWaitingModal(false);
    showWaitingModal_ = false;
    // Establish WebSocket connection
    socketRef.current = new W3CWebSocket(WEBSOCKET_URL);
    // Set up event listener for WebSocket connection
    socketRef.current.onopen = () => {
      console.log('WebSocket connection established.');
    };
    let res = '';
    socketRef.current.onmessage = (event) => {
      // console.log("client says server message received: ", event);
      console.log("client says server message received: ", event['data']);
      res = JSON.parse(event['data']);
      if (res.result) {
        detection_result = res.result;
      }
      if (res.exercise_id && res.message === 'record_created') {
        exercise_instance_id = res.exercise_id;
        saveDataToBackend().then((res) => {
          console.log(res);
          // Set savingData to false after saving is completed to hide the modal
          setShowWaitingModal(false);
          showWaitingModal_ = false;
          setShowNextExerciseModal(true);

          showNextModal_ = true;
        });
      }
      // console.log("responseee stat:", res);
    }
    socketRef.current.error = (error) => {
      console.log("websocket error:", error);
    }
    setPoseDetected(false);
    setStartVideo(true);

  }

  const newRecordCreationRequest = () => {

    let message = {
      message: "exercise_completed",
      'exer_name': exerciseId,
      'session_id': sessionId,
      'user_location': userLocation
    }
    socketRef.current.send(JSON.stringify(message));
  }

  const getCameraInfo = async () => {
    let cam_type = ''
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter(device => device.kind === 'videoinput');
      if (cameras.length > 0) {
        // console.log('Camera Type:', cameras[0].label);
        cam_type = cameras[0].label
      } else {
        console.log('No cameras found.');
      }
      return cam_type
    } catch (error) {
      console.error('Error getting camera info:', error);
    }
  };

  const fetchData = async () => {
    if (Object.keys(userMetaData).length === 0) {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log("userTimezone in exercise tracker: ", userTimezone);
      userMetaData = userTimezone;
      userLocation = userTimezone;
      if (userLocation === 'Asia/Tehran') {
        SAVE_VIDEO = false;
      }
    }
  };

  const saveDataToBackend = async () => {
    let message = {
      message: "saving_started",
      'exer_name': exerciseId,
      'session_id': sessionId,
      'user_location': userLocation
    }
    socketRef.current.send(JSON.stringify(message));
    // console.log("image list is:", images_list);
    let response = '';
    if (SAVE_VIDEO) {
      let data_array = {
        'exercise_id': exercise_instance_id,
        'images': images_list
      }
      try {
        response = await axios({
          headers: {
          },
          timeout: 100000,
          method: 'post',
          url: API_URL,
          data: data_array
        });
      } catch (e) {
        console.log("error in saving image is: ", e);
        return "error in saving image";
      }
    }
    return response;
  }


  const continueExercising = () => {
    lastActivityTimeRef.current = new Date(Date()).getTime();
    setShowContinoueExercisesModal(false);
    setModalOverlay(false);
  }

  const emptyVariables = (message) => {
    setStartVideo(false);
    // close websocket
    socketRef.current.close();
    // close camera
    camera.stop();
    let stream = webcamRef.current.video.srcObject;
    const tracks = stream.getTracks();
    tracks[0].stop();
    webcamRef.current.video.srcObject = null;
    camera = null;
    //clear timer for automated next clicked
    clearTimeout(timerForAutomatedNext);
    // set variables to initial values
    batch_data = [];
    images_list = [];
    setPercentage(0);
    detection_result = '';
    setPoseDetected(false);
    setSuggestion('');
    side = '';
    leftSide = false;
    rightSide = false;
    connection_color = 'black';
    setFeedbackText("Ensure you are positioned correctly within the camera frame.\nThen, wait for the system to detect your skeleton");

    props.onComplete(message);
  }

  const quit = () => {
    console.log("quit clicked");
    setStartVideo(false);
    camera.stop();

    let stream = webcamRef.current.video.srcObject;
    const tracks = stream.getTracks();
    tracks[0].stop();
    webcamRef.current.video.srcObject = null;
    socketRef.current.close();
    batch_data = [];
    images_list = [];
    setPercentage(0);
    detection_result = '';
    setPoseDetected(false);
    setSuggestion('');
    side = '';
    leftSide = false;
    rightSide = false;
    camera = null;
    connection_color = 'black';
    setShowContinoueExercisesModal(false);
    props.onQuit();
    // navigate(`/`);
  }

  const _renderNextModal = () => {
    return (
      <NextExerciseSelectingModal
        onRetry={() => emptyVariables(exerciseIndex)}
        onNext={() => emptyVariables(exerciseIndex + 1)}
        onExit={quit}
        progress={progress}
        showNextButton={showNextButton}
      />
    );
  }


  const _renderContinoueExercisingModal = () => {
    return (
      <ContinoueExercisingModal
        onContinue={continueExercising}
        onExit={quit} />
    )
  }

  const _renderOnScreenContainer = () => {
    return (
      <div className="OnScreenContainer"
        style={{
          backgroundColor: modalOverlay ? 'black' : '',
          opacity: modalOverlay ? 0.5 : 1,
        }}
      >
        <div className="FeedbackContainer"
          style={{ backgroundColor: poseDetected ? "#00252e" : '', }}>
          <div className="FeedbackText">
            {feedbackText.split('\n').map((line, lineIndex) => (
              <div key={lineIndex} className="FeedbackText">
                {line}
              </div>
            ))}
            {/* {feedbackText} */}
          </div>
          <p className="FeedbackText">
            {suggestion}
          </p>
        </div>
        <div className="OnScreenImageContainer">
          <img className="PoseImage"
            src={IMAGES[exerciseId]} />
        </div>
        <div className="ProgressBarContainer">
          <CircularProgressbar
            styles={
              buildStyles({
                pathColor: `rgb(44,100,86, ${percentage / 100})`,
                textColor: 'rgb(44,100,86)',
                trailColor: 'gray',
                backgroundColor: 'gray',
              })}
            circleRatio={1}
            value={percentage} text={`${percentage}%`} />
        </div>
        <div className="TimerContainer"
          style={{ backgroundColor: poseDetected ? "#00252e" : '' }}>
          <div className="TimerText">{timer}</div>
        </div>
      </div>
    )
  }

  const _renderStartedExercise = () => {
    return (
      <div
        // className="StartContainer"
        style={{
          display: "flex",
          position: 'relative',
          alignItems: "center",
          justifyContent: "center",
          width: '100%',
          height: '100%',
          backgroundColor: '#282c34',
        }}
      >
        <Webcam
          videoConstraints={{
            // height: videoHeight,
            // width: videoWidth,
            // width: orientationMode !== 'portrait-primary' ? '100%' : undefined,
            // height: orientationMode === 'portrait-primary' ? '100%' : undefined,
            width: '100%',
            aspectRatio: 1.6,
            // aspectRatio: videoWidth > videoHeight ? videoWidth/videoHeight : videoHeight/videoWidth,
          }}
          style={{
            display: poseDetected ? 'none' : 'inline',
            // position: "absolute",
            // left: '0',
            // right: '0',
            width: orientationMode === 'portrait-primary' ? '100%' : 'auto',
            height: orientationMode !== 'portrait-primary' ? '100%' : 'auto',
            // height: `${(videoWidth/videoHeight) * 100}%`,
          }}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          id="webcam" />
        <canvas id="my-canvas"
          ref={canvasRef}
          style={{
            display: poseDetected ? 'inline' : 'none',
            // position: "absolute",
            // left: '0',
            // right: '0',
            // width: "100%",
            width: orientationMode === 'portrait-primary' ? '100%' : 'auto',
            height: orientationMode !== 'portrait-primary' ? '100%' : 'auto',
          }}
        >
        </canvas>
        <div
          // className="RedTransparentContainer"
          style={{
            // display: poseDetected ? 'none' : 'block',
            backgroundColor: poseDetected ? 'red' : 'red',
            position: 'absolute',
            width: poseDetected ? '0' : '100%',
            height: poseDetected ? '0' : '100%',
            opacity: '0.2',
            zIndex: '9999',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
          }}>
        </div>
        {_renderOnScreenContainer()}
        <div className="RootModalExercise">
          {showWaitingModal && <WaitingModal />}
        </div>
        <div className="RootModalExercise">
          {showNextExerciseModal && _renderNextModal()}
        </div>
        <div className="RootModalExercise">
          {showContinoueExersingModal && _renderContinoueExercisingModal()}
        </div>
      </div>
    )
  }


  if (startVideo) {
    return (
      <div
        style={{
          backgroundColor: 'yellow',
          width: '100vw',
          height: '100vh'
        }}
      >
        {_renderStartedExercise()}
      </div>);
  }
}

export default injectIntl(SequentialExercises);
