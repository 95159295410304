import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import { injectIntl} from 'react-intl';

import Button from '@mui/material/Button';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Webcam from "react-webcam";
import * as cam from "@mediapipe/camera_utils";
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { Pose, POSE_CONNECTIONS } from '@mediapipe/pose';

import { w3cwebsocket as W3CWebSocket } from "websocket";

import '../style/SingleExercise.css';
import { exercise_config_variables } from "../constants/SettingConstant";



//left shoulder, right shoulder, left elbow, right elbow, left wrist, right wrist, left hip, right hip, nose
const joint_idx = [11, 12, 13, 14, 15, 16, 23, 24];
let side = '';
let camera = null;
const API_URL = exercise_config_variables.API_URL;
const WEBSOCKET_URL = exercise_config_variables.WEBSOCKET_URL;
const API_URL_CREATE_SESSION = exercise_config_variables.API_URL_CREATE_SESSION;
const BATCH_SIZE = exercise_config_variables.BATCH_SIZE;
const SAVE_VIDEO = false;
let batch_data = [];

let detection_result = '';
let exercise_instance_id = 0;
let images_list = [];
let session_id = 0;
function SingleExercise(props) {

  const [feedbackText, setFeedbackText] = useState("Ensure you are positioned correctly within the camera frame.\nThen, wait for the system to detect your skeleton")
  const [poseDetected, setPoseDetected] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [timer, setTimer] = useState("00:00:10");
  const [startingTime, setStartingTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [startVideo, setStartVideo] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [videoWidth, setVideoWidth] = useState(null);
  const [videoHeight, setVideoHeight] = useState(null);
  const [orientationMode, setOrientationMode] = useState(window.screen.orientation.type); //portrait-primary' 'landscape-primary'



  let exerciseId = props.exerciseId;
  let exerciseLabel = props.exerciseLabel;
  let exerciseExplanation = props.exerciseExplanation;
  let exerciseBenefit1 = props.exerciseBenefit1;
  let exerciseBenefit2 = props.exerciseBenefit2;
  let leftSideIndx = props.exerciseleftIdx;
  let rightSideIndx = props.exerciseRightIdx;
  const IMAGES = {
    seated_idle: require('../assets/images/exercise-0.JPG'),
    Seated_Side_Stretch: require('../assets/images/Seated_Side.JPG'),
    Shoulder_Warmup: require("../assets/images/Shoulder_Warmup.JPG"),
    Upward_Hand_Stretch: require("../assets/images/Hand_Up.JPG"),
    Triceps_Stretch: require("../assets/images/Triceps.JPG"),
    Neck_Stretch: require("../assets/images/Neck_Stretch.JPG"),
  }
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const socketRef = useRef();
  let connection_color = 'black';

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     setVideoHeight(window.innerHeight);
  //     setVideoWidth(window.innerWidth);
  //   };

  //   // Run the effect immediately and every time the orientation changes
  //   window.addEventListener('orientationchange', handleOrientationChange);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('orientationchange', handleOrientationChange);
  //   };
  // }, []);

  useEffect(() => {
    const updateVideoDimensions = () => {
      setVideoHeight(window.innerHeight);
      setVideoWidth(window.innerWidth);
      console.log("window inner height: ", window.innerHeight)
      console.log("window inner width: ", window.innerWidth)
    };

    const handleOrientationChange = (event) => {
      setOrientationMode(event.target.screen.orientation.type);
    };


    // Run the effect immediately and every time the orientation changes
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', updateVideoDimensions);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('resize', updateVideoDimensions);
    };
  }, []);

  useEffect(() => {
    // Clean up WebSocket connection on component unmount
    return () => {
      socketRef && socketRef.current && socketRef.current.close();
    };

  }, []);

  useEffect(() => {
    const onResults = (results) => {
      if (!results.poseLandmarks || webcamRef.current == null) {
        setPoseDetected(false);
        setFeedbackText("Ensure you are positioned correctly within the camera frame.\nThen, wait for the system to detect your skeleton");
        return;
      }
      else {
        setPoseDetected(true);
      }

      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      let notDetected = 0

      // Set canvas width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
      const canvasElement = canvasRef.current;
      const canvasCtx = canvasElement.getContext("2d")
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasCtx.drawImage(
        results.image,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      const new_connections = [...POSE_CONNECTIONS];
      const landmarks = [...results.poseLandmarks];
      drawConnectors(canvasCtx,
        results.poseLandmarks, POSE_CONNECTIONS,
        { color: connection_color, lineWidth: 5 });
      // The dots are the landmarks
      drawLandmarks(canvasCtx, results.poseLandmarks,
        { color: 'white', lineWidth: 10, radius: 2 });
      canvasCtx.restore();
      let input = []
      let temp = landmarks.map((keypoint, idx) => {
        if (joint_idx.includes(idx)) {
          input.push(keypoint.x)
          input.push(keypoint.y)
          input.push(keypoint.z)
          input.push(keypoint.visibility)
        }
      })
      // let embedding = tf.reshape(input, [1, 32]);
      // let input_matrix = tf.reshape(input, [8, 4]);
      // let predicted_prob = 0;
      let text = "";
      let perc = 0;
      let exer_status = '';

      let max_index = 0;
      // socketRef.current.send(JSON.stringify(landmarks));
      // console.log("input:", input);
      batch_data.push(input);
      const image = webcamRef.current.getScreenshot();
      images_list.push(image);
      // console.log("batch_data:", batch_data.length);
      if (webcamRef.current !== null && batch_data.length === BATCH_SIZE) {
        batch_data = [];

        detection_exercise(input);
      }
      if (socketRef && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        max_index = detection_result['label'];
        //   console.log("label of exer:", max_index);
        perc = detection_result['score'];
        exer_status = detection_result['status'];
        setSuggestion(detection_result['suggestion']);

        if (max_index === 0) {
          connection_color = 'red';
          /// start of session
          // if (side === '') {
          side = '';
          text = "Start Exercising";
          setFeedbackText(text);
          setPercentage(0);
          setTimer(("00:00:10"));

        }
        else if (max_index === leftSideIndx) {
          if (exer_status === 'perfect') {
            connection_color = 'green';
          }
          else if (exer_status === 'acceptable') {
            connection_color = 'purple';
          }
          if (side !== 'left') {
            setStartingTime(new Date(Date()).getTime());
            side = 'left';
          }
          setCurrentTime(new Date(Date()).getTime());
          if (exerciseId === 'Upward_Hand_Stretch' || exerciseId === 'Shoulder_Warmup' || exerciseId === 'Triceps_Stretch') {
            text = "Ok! You're ready to start. Hold this pose for 10 seconds.";
          }
          else {
            text = "“Ok! Begin with your left hand. Maintain this pose for 10 seconds.";
          }
          setFeedbackText(text);
          // perc = predicted_prob.toFixed(2)* 100;
          setPercentage(perc);
        }
        else if (max_index === rightSideIndx) {
          if (exer_status === 'perfect') {
            connection_color = 'green';
          }
          else if (exer_status === 'acceptable') {
            connection_color = 'purple';
          }
          if (side !== 'right') {
            setStartingTime(new Date(Date()).getTime());
            side = 'right';
          }
          setCurrentTime(new Date(Date()).getTime());
          if (exerciseId === 'Upward_Hand_Stretch' || exerciseId === 'Shoulder_Warmup' || exerciseId === 'Triceps_Stretch') {
            text = "Ok! You're ready to start. Hold this pose for 10 seconds.";
          }
          else {
            text = "Ok! Begin with your right hand. Maintain this pose for 10 seconds.";
          } setFeedbackText(text);
          // perc = predicted_prob.toFixed(2)* 100;
          setPercentage(perc);

        }
      }
      else {
        connection_color = 'red';
        text = "Sorry!!! Something went wrong";
        setFeedbackText(text);
      }
    }
    if (startVideo) {
      const pose = new Pose({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
        },
      });
      pose.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });
      pose.onResults(onResults);
      if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null) {
        camera = new cam.Camera(webcamRef.current.video, {
          onFrame: async () => {
            await pose.send({ image: webcamRef.current.video });
          },
          width: window.innerWidth,
          height: window.innerHeight,
        });
        camera.start();
      }
    }
  }, [startVideo]);


  useEffect(() => {
    if (startVideo) {
      const timeDiff = (currentTime - startingTime) / 1000;
      if (side !== '') {
        if (timeDiff < 11) {
          let t = 10 - timeDiff
          setTimer('00:00:' + t);
        }
        else {
          if (side === 'left') {
          }
          if (side === 'right') {
          }
        }
      }
      else {
      }
    }
  }, [currentTime, startVideo])


  const detection_exercise = (joints_array) => {
    // const image = webcamRef.current.getScreenshot();
    // images_list.push(image);
    let data_arra = {
      'joints': joints_array,
      'exer_name': exerciseId,
      'session_id': session_id,
    }
    console.log("data array: ", data_arra)
    socketRef.current.send(JSON.stringify(data_arra));

  }


  const createNewSession = async () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data_array = {
      'session_name': "single exercise",
      'user_location': userTimezone
    }
    let response = '';
    try {
      response = await axios({
        headers: {
        },
        timeout: 100000,
        method: 'post',
        url: API_URL_CREATE_SESSION,
        data: data_array
      });
      return response;
    } catch (e) {
      console.log("error in creating new session is: ", e);
      return "error in creating new session";
    }
  }

  const startCam = () => {
    console.log("start");
    detection_result = '';
    images_list = [];
    side = '';
    camera = null;
    connection_color = 'black';
    createNewSession().then((response) => {
      console.log(response);
      session_id = response["data"].session_id;
      socketRef.current = new W3CWebSocket(WEBSOCKET_URL);
      // Set up event listener for WebSocket connection
      socketRef.current.onopen = () => {
        console.log('WebSocket connection established.');
        let data = {
          "message": "exercise_completed",
          'exer_name': exerciseId,
          'session_id': session_id,
        }
        socketRef.current.send(JSON.stringify(data));
      };
      let res = '';
      socketRef.current.onmessage = (event) => {
        // console.log("client says server message received: ", event);
        console.log("client says server message received: ", event['data']);
        res = JSON.parse(event['data']);
        if (res.result) {
          detection_result = res.result;
        }
        if (res.exercise_id) {
          exercise_instance_id = res.exercise_id;
        }
        // console.log("responseee stat:", res);
      }
      socketRef.current.error = (error) => {
        console.log("websocket error:", error);
      }
      setPoseDetected(false);
      setStartVideo(true);
    });


  }
  const stopCam = async () => {
    setStartVideo(false);
    camera.stop();
    let message = {
      "message": "saving_started",
      'exer_name': exerciseId,
      'session_id': session_id
    }
    socketRef.current.send(JSON.stringify(message));


    socketRef.current.close();
    if (SAVE_VIDEO) {
      let data_array = {
        'exercise_id': exercise_instance_id,
        'images': images_list
      }
      try {
        let response = await axios({
          headers: {
          },
          method: 'post',
          url: API_URL,
          data: data_array
        });
      } catch (e) {
        console.log("error in saving image is: ", e)
      }
    }
    batch_data = [];
    images_list = [];
    let stream = webcamRef.current.video.srcObject;
    const tracks = stream.getTracks();
    tracks[0].stop();
    webcamRef.current.video.srcObject = null;
    setPercentage(0);
    detection_result = '';
    setPoseDetected(false);
    setSuggestion('');
    side = '';
    camera = null;
    connection_color = 'black';

    setFeedbackText("Ensure you are positioned correctly within the camera frame.\nThen, wait for the system to detect your skeleton");
  }

  const _renderStoppedExercise = () => {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <div className="StopContainer">
          <div className="DescriptionContainer">
            <ul>
              <p className="LabelText">
                {exerciseLabel}:
              </p>
              <p></p>
              <li className="WhiteTextStyleBullet">
                {exerciseExplanation}
              </li>
              <p></p>
              <li className="WhiteTextStyleBullet">
                {exerciseBenefit1}
              </li>
              <p></p>
              <li className="WhiteTextStyleBullet">
                {exerciseBenefit2}
              </li>
            </ul>
          </div>
          <div className="ImageContainer">
            <img className="PoseImage"
              src={IMAGES[exerciseId]} />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop:'20px'}}>
          <div className="ButtonContainer">
          <Button sx={{borderColor:'#00252e', backgroundColor:'#6fc9a6', color:'white', '&:hover': {
                        backgroundColor: '#6fc9a6', 
                    },}} variant="contained" onClick={startCam}>Start Exercising</Button>
            {/* <button className="ButtonStyleStoppingView"
              onClick={startCam}
            >Start Exercising</button> */}
          </div>
        </div>
      </div>
    )
  }
  const _renderStartedExercise = () => {
    return (
      <div className="StartContainer">
        <Webcam
          videoConstraints={{
            // height: videoHeight,
            // width: videoWidth,
            // // aspectRatio: 1.6
            width: '100%',
            aspectRatio: 1.6,
          }}
          // className="WebcamStyle"
          style={{
            display: poseDetected ? 'none' : 'inline',
            // position: "absolute",
            // left: '0',
            // right: '0',
            width: orientationMode === 'portrait-primary' ? '100%' : 'auto',
            height: orientationMode !== 'portrait-primary' ? '100%' : 'auto',
            // height: `${(videoWidth/videoHeight) * 100}%`,
          }}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          id="webcam" />
        <canvas id="my-canvas"
          ref={canvasRef}
          style={{
            position: "absolute",
            left: '0',
            // width: "100%",
            // height: "100%",
            width: orientationMode === 'portrait-primary' ? '100%' : 'auto',
            height: orientationMode !== 'portrait-primary' ? '100%' : 'auto',
          }}>
        </canvas>
        <div className="RedTransparentContainer"
          style={{
            backgroundColor: poseDetected ? '' : 'red',
          }}>
        </div>
        <div className="OnScreenContainer">
          <div className="EmptyContainer">
            <p></p>
          </div>
          <div className="FeedbackContainer"
            style={{ backgroundColor: poseDetected ? "#00252e" : '', }}>
            <div className="FeedbackText">
              {feedbackText.split('\n').map((line, lineIndex) => (
                <div key={lineIndex} className="FeedbackText">
                  {line}
                </div>
              ))}
              {/* {feedbackText} */}
            </div>
            <p className="FeedbackText">
              {suggestion}
            </p>
          </div>
          <div className="OnScreenImageContainer">
            <img className="PoseImage"
              src={IMAGES[exerciseId]} />
              <Button sx={{borderColor:'#00252e', backgroundColor:'#6fc9a6', color:'white',marginTop:'5px', '&:hover': {
                        backgroundColor: '#6fc9a6', 
                    },}} variant="contained" onClick={stopCam}>Stop Exercising</Button>
           
            {/* <button className="StopSingleExerciseButtonStyle"
              onClick={stopCam}>
              Stop Exercising
            </button> */}
          </div>
          {/* <div className="OnScreenButtonContainer">
            <button className="ButtonStyle"
              onClick={stopCam}>
              Stop Exercising
            </button>
          </div> */}
          <div className="ProgressBarContainerSingleExercise">
            <CircularProgressbar
              styles={
                buildStyles({
                  pathColor: `rgb(44,100,86, ${percentage / 100})`,
                  textColor: 'rgb(44,100,86)',
                  trailColor: 'gray',
                  backgroundColor: 'gray',
                })}
              circleRatio={1}
              value={percentage} text={`${percentage}%`} />
          </div>
          <div className="TimerContainerSingleExercise"
            style={{ backgroundColor: poseDetected ? "#00252e" : '' }}>
            <div className="TimerTextSingleExercise">{timer}</div>
          </div>
        </div>
      </div>
    )
  }
  if (startVideo) {
    return (
      <div>
        {_renderStartedExercise()}
      </div>);
  }
  else {
    return (
      <div>
        {_renderStoppedExercise()}
      </div>)
  }
}

export default injectIntl(SingleExercise);
