import React from 'react';
import {Routes, Route} from 'react-router-dom';
import RouteWrapper from './Route';
// import SignIn from '../pages/SignIn';
// import SignUp from '../pages/SignUp';
import Home from '../pages/Home';
import Exercise from '../pages/Exercise';
import ExerciseTracker from '../pages/ExerciseTracker';
import Tutorial from '../pages/Tutorial';

// import Dashboard from '../pages/Dashboard';

export default function AllRoutes() {
  return (
    <Routes>
      {/*<Route path="/" exact element={<Home/>}/>*/}
      <Route path="/" exact element={<RouteWrapper isPrivate element={<Home/>}/>}/>
      <Route path="/exercise/:exerciseId" element={<RouteWrapper isPrivate element={<Exercise/>}/>}/>
      <Route path="/exercise-tracker/:session_id" exact element={<RouteWrapper isPrivate element={<ExerciseTracker/>}/>} />
      <Route path="/tutorial" exact element={<RouteWrapper isPrivate element={<Tutorial/>}/>} />
    </Routes>
  );
}
