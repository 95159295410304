import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@material-ui/core';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from "react-router-dom";
import { exercise_config_variables } from "../constants/SettingConstant";
import CameraAccessDeniedModal from "../components/CameraAccessDeniedModal";
import axios from "axios";
const API_URL_CREATE_SESSION = exercise_config_variables.API_URL_CREATE_SESSION;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    marginTop: '50px',
    backgroundColor: '#203D4E'
  },
}));

const Header = () => {

  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [showCameraAccessDeniedModal, setShowCameraAccessDeniedModal] = useState(false);
  const logo_image = require("../assets/images/app_logo.JPG");
  const exerciseItems = [
    { label: 'Shoulder Warm-Up', path: `/exercise/Shoulder_Warmup` },
    { label: 'Seated Side Stretch', path: `/exercise/Seated_Side_Stretch` },
    { label: 'Upward Hand Stretch', path: `/exercise/Upward_Hand_Stretch` },
    { label: 'Triceps Stretch', path: `/exercise/Triceps_Stretch` },
    { label: 'Neck Stretch', path: `/exercise/Neck_Stretch` },
  ];
  const menuItems = [
    { label: 'Tutorial', path: `/tutorial` },
  ];
  const classes = useStyles();
  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  const toggleSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleStartExercising = () => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const hasVideoInput = devices.some(device => device.kind === 'videoinput');
      console.log(hasVideoInput)
      if (!hasVideoInput) {
        console.error('No video input devices found');
        setShowCameraAccessDeniedModal(true);

      }
      else {
        console.log("has video input true");
        createNewSession().then((res) => {
          console.log(res);
          let session_id = res["data"].session_id;
          navigate(`/exercise-tracker/${session_id}`);
        });

      }
    })
      .catch(error => {
        console.error('Error checking camera access:', error);
      });
  }

  const createNewSession = async () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data_array = {
      'session_name': "multiple exercises",
      'user_location': userTimezone
    }
    let response = '';
    try {
      response = await axios({
        headers: {
        },
        timeout: 100000,
        method: 'post',
        url: API_URL_CREATE_SESSION,
        data: data_array
      });
      return response;
    } catch (e) {
      console.log("error in creating new session is: ", e);
      return "error in creating new session";
    }
  }

  const _renderCameraAccessDeniedModal = () => {
    return (<CameraAccessDeniedModal />)
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar style={{ backgroundColor: "#00252e" }}>
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <MenuIcon onClick={toggleDrawer()} />
            </IconButton>
            <Link to="/">
              <img src={logo_image} style={{ height: "50px" }} />
            </Link>
          </Toolbar>
        </AppBar>

      </Box>
      <Drawer classes={{ paper: classes.drawerPaper }}
        PaperProps={{
          sx: {
            backgroundColor: "#203D4E",
          }
        }}
        anchor="left" variant="temporary" open={drawerOpen} onClose={toggleDrawer()} sx={{ zIndex: 1200 }}>
        <List>
          <ListItem
            button
            onClick={() => toggleSubMenu()}
          >
            <ListItemText primary="Exercises" style={{ color: 'white' }} />
            {openSubMenu ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
          </ListItem>
          <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
            <List style= {{  borderBottom: '1px solid #ccc',  borderTop: '1px solid #ccc'   }}component="div" disablePadding>
              {exerciseItems.map((exercise) => (
                <ListItem
                  button
                  key={exercise.label}
                  component={Link}
                  to={exercise.path}
                  onClick={() => setDrawerOpen(false)}
                >
                  <ListItemText primary={exercise.label} style={{ color: 'white' }} />
                </ListItem>
              ))}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => (handleStartExercising())}
          >
            <ListItemText primary="Break From Work" style={{ color: 'white' }} />
          </ListItem>

          {menuItems.map((item) => (
            <ListItem
              button
              key={item.label}
              component={Link}
              to={item.path}
              onClick={() => setDrawerOpen(false)}
            >
              <ListItemText primary={item.label} style={{ color: 'white' }} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div>
        {showCameraAccessDeniedModal && _renderCameraAccessDeniedModal()}
      </div>
    </div>
  );
};
export default Header;