import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Footer from "../components/Footer";
import Header from "../components/Header";

import { tutorialData } from "../constants/TutorialConstant";
import { exercise_config_variables } from "../constants/SettingConstant";
import CameraAccessDeniedModal from "../components/CameraAccessDeniedModal";
import '../style/Tutorial.css';

import axios from "axios";

const API_URL_CREATE_SESSION = exercise_config_variables.API_URL_CREATE_SESSION;
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Tutorial(props) {
    const [safariBrowser, setSafariBrowser] = useState(false);
    const [showCameraAccessDeniedModal, setShowCameraAccessDeniedModal] = useState(false);
    const [skipTutorialModal, setSkipTutorialModal] = useState(false);

    const { intl } = props;
    const navigate = useNavigate();
    const smartyXUrl = 'https://www.smartyx.ai/term-and-condition/#term_and_condition';
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = tutorialData.length;

    useEffect(() => {
        setSkipTutorialModal(true);
        const userAgent = window.navigator.userAgent;
        console.log("UserAgent: " + userAgent.includes('Safari'))
        if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
            setSafariBrowser(true);
        }
    }, []);

    const handleNext = () => {
        if (activeStep === maxSteps - 1) {
            setActiveStep(0);
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleContactUsButtonClick = () => {
        console.log("Button clicked!");
        const url = "https://smartyx.ai/contact";
        window.open(url);
    }

    const handleStartButtonClick = () => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const hasVideoInput = devices.some(device => device.kind === 'videoinput');
            console.log(hasVideoInput)
            if (!hasVideoInput) {
                console.error('No video input devices found');
                setShowCameraAccessDeniedModal(true);

            }
            else {
                console.log("has video input true");
                createNewSession().then((res) => {
                    console.log(res);
                    let session_id = res["data"].session_id;
                    navigate(`/exercise-tracker/${session_id}`);
                });

            }
        })
            .catch(error => {
                console.error('Error checking camera access:', error);
            });

    }

    const createNewSession = async () => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data_array = {
            'session_name': "multiple exercises",
            'user_location': userTimezone
        }
        let response = '';
        try {
            response = await axios({
                headers: {
                },
                timeout: 100000,
                method: 'post',
                url: API_URL_CREATE_SESSION,
                data: data_array
            });
            return response;
        } catch (e) {
            console.log("error in creating new session is: ", e);
            return "error in creating new session";
        }
    }

    const _renderCameraAccessDeniedModal = () => {
        return (<CameraAccessDeniedModal />)
    }
    const handleClickOpen = () => {
        setSkipTutorialModal(false);
    };
    const _renderSkipTutorialModal = () => {
        return (
            <Dialog
                open={true}
                onClose={handleClickOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>
                        Do you want to see the tutorial?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: '#00252e' }} autoFocus onClick={handleClickOpen}>
                        Read Tutorial
                    </Button>
                    <Button sx={{ color: '#00252e' }} autoFocus onClick={handleStartButtonClick}>
                        Start Exercising
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

    return (
        <div className='RootHome'>
            <div className='HeaderContainer'>
                <Header />
            </div>
            <div className='PageContainer'>
                <div className='PlanContainerTutor' >
                    <div className='TextContainer'>
                        {/* <div className='TextStyleTitle'>Welcome to the FitU2 Tutorial! Let's Get Started with Your Fitness Journey!</div> */}
                        {safariBrowser && <div className='TextStyleBestBrowser'>For the best experience, we recommend using Chrome or Firefox.</div>}
                    </div>
                    <div className='TextTermCondition'>
                        <div className='TextStyleTermCondition'>
                            If you click on start , you accept SmartyX <a href={smartyXUrl}>Terms and Conditions</a>.
                        </div>
                    </div>
                    <div className='rowStyleSteps'>
                        <div className="TutorialStepsContainer">
                            <div className="containertutorialText">
                                <Typography
                                    className="tipTitle"
                                    variant='h6'
                                >
                                    {tutorialData[activeStep].title}
                                </Typography>
                                <Typography
                                    className="bodyText"

                                    variant="body1" component="p" align="justify">
                                    {tutorialData[activeStep].text}
                                </Typography>
                                {tutorialData[activeStep].bullet.split('\n').map((line2, lineIndex2) => (
                                    line2.trim() !== "" && (
                                        <li className="listItem" key={lineIndex2} variant="body1" component="p" align="justify">
                                            {line2}
                                        </li>
                                    )
                                ))}
                                {
                                    tutorialData[activeStep].extra.trim() !== "" && (
                                        <Typography className="bodyText"
                                            variant="body1" component="p" align="justify">
                                            {tutorialData[activeStep].extra}
                                        </Typography>
                                    )
                                }
                            </div>
                            <Box className='ImageBoxStyle'>
                                <AutoPlaySwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={activeStep}
                                    style={{
                                        display: 'flex',
                                        maxWidth: '100%', // Adjust the maximum width as needed
                                        maxHeight: '50vh',
                                        // margin: 'auto',
                                    }}
                                >
                                    {tutorialData.map((step, index) => (
                                        <div key={step.title}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        display: 'block',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                    src={step.image}
                                                    alt={step.title}
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>

                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                            Next
                                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                            Back
                                        </Button>
                                    }
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            <div className="FixedButtonContainer">
                <Button sx={{
                    borderColor: '#00252e', backgroundColor: '#6fc9a6', color: 'white', '&:hover': {
                        backgroundColor: '#6fc9a6',
                    },
                }} variant="contained" onClick={handleStartButtonClick}>Start Exercising</Button>
            </div>
            <div>
                {showCameraAccessDeniedModal && _renderCameraAccessDeniedModal()}
            </div>
            <div>
                {skipTutorialModal && _renderSkipTutorialModal()}
            </div>
            <div className='FooterContainer'>
                <Footer />
            </div>
        </div>
    );
}

export default injectIntl(Tutorial);
