import React from 'react';
import {Router, BrowserRouter} from 'react-router-dom';
import history from './services/history';
import Routes from './routes';


function App() {

  return (
    <BrowserRouter location={history.location} navigator={history}>
      <Routes/>
    </BrowserRouter>
  );
}

export default App;
