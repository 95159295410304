import React from 'react';
import {useParams} from "react-router-dom";
import {injectIntl, FormattedMessage} from 'react-intl';

import {exercise_items} from "../constants/ExerciseConstant";
import SingleExercises from "../components/SingleExercise";
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../style/Exercise.css';



function Home(props) {
  const { intl } = props;
  let { exerciseId } = useParams();

  let targetExercise = exercise_items.filter((exercise_item, index)=>exercise_item.key === exerciseId)
  if (targetExercise && targetExercise.length) targetExercise = targetExercise[0]

  return (
    <div className='RootExercise'>
      <div className='HeaderContainer'>
        <Header/>
      </div>
      <div className='ExerciseMonitorContainer'>

         <SingleExercises exerciseId = {targetExercise.key} exerciseLabel = {targetExercise.label} exerciseleftIdx = {targetExercise.leftSideIndx} exerciseRightIdx = {targetExercise.rightSideIndx} exerciseExplanation={targetExercise.explanation} exerciseBenefit1={targetExercise.benefit1} exerciseBenefit2={targetExercise.benefit2}/>
        {/* <ExerciseMonitoring exerciseId = {targetExercise.key} exerciseLabel = {targetExercise.label} exerciseleftIdx = {targetExercise.leftSideIndx} exerciseRightIdx = {targetExercise.rightSideIndx} exerciseExplanation={targetExercise.explanation} exerciseBenefit1={targetExercise.benefit1} exerciseBenefit2={targetExercise.benefit2}/> */}
      </div>
      <div className='FooterContainer'>
          <Footer/>
      </div>
    </div>
  );
}

export default injectIntl(Home);
