import React, { useState, useEffect, } from 'react';
import { useNavigate } from "react-router-dom";
import { injectIntl} from 'react-intl';
import SequentialExercises from '../components/SequentialExercises';
import { exercise_items } from "../constants/ExerciseConstant";
import { useParams } from 'react-router-dom';

function ExerciseTracker(props) {
  const session_id = useParams()['session_id'];
  console.log(session_id);
  const navigate = useNavigate();
  const exercises = exercise_items;
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [renderIndex, setRenderIndex] = useState(0);

  const handleExerciseComplete = (id) => {
    // Save the result of the completed exercise
    console.log(id);
    // Move to the next or previous exercise
    let updated_render_index = renderIndex + 1;
    setRenderIndex(updated_render_index);
    setCurrentExerciseIndex(id);
    console.log("exer index:", currentExerciseIndex);
    console.log("exer len:", exercises.length);
  };

  const handleQuit = () => {
    navigate(`/`);
  };

  useEffect(() => {
    console.log('Current Exercise Index:', currentExerciseIndex);
  }, [currentExerciseIndex]);
  if (currentExerciseIndex >= exercises.length) {
    // All exercises are completed
    navigate(`/`);
    return (
      <div>
        <p>Congratulations! You have completed all the exercises.</p>
      </div>
    );
  }

  const currentExercise = exercises[currentExerciseIndex];

  return (
    <div>
      <SequentialExercises
        sessionId={session_id}
        exerciseIndex={exercises[currentExerciseIndex].idx}
        exerciseId={exercises[currentExerciseIndex].key}
        exerciseLabel={exercises[currentExerciseIndex].label}
        exerciseleftIdx={exercises[currentExerciseIndex].leftSideIndx}
        exerciseRightIdx={exercises[currentExerciseIndex].rightSideIndx}
        exerciseLength = {exercises.length}
        key={renderIndex}
        onComplete={handleExerciseComplete}
        onQuit={handleQuit}
      />
    </div>
  );
};


export default injectIntl(ExerciseTracker);





