import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from "react-intl";
import 'bootstrap/dist/css/bootstrap.css';

import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";


const messages = {
  de: messages_de,
  en: messages_en
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <IntlProvider
    locale={"en"}
    messages={messages["en"]}
  >
    <App />
  </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
