import { Modal, Box, Typography } from '@mui/material';

function WaitingModal({ open, onClose }) {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <Box sx={{ bgcolor: 'background.paper', p: 3, minWidth: 1/2, }} style={{ 'borderRadius': '10px', 'alignContent':'center'}}>
                <Typography variant="h6" color="#1C5454" align='center'>
                    Please wait until your data is saved.
                </Typography>
            </Box>
        </div>
    );
}
export default WaitingModal;