import tutorial_image_1 from "../assets/tutorial_images/01.JPG";
import tutorial_image_2 from "../assets/tutorial_images/03.JPG";
import tutorial_image_4 from "../assets/tutorial_images/04.JPG";
import tutorial_image_5 from "../assets/tutorial_images/05.JPG";
import tutorial_image_6 from "../assets/tutorial_images/06.JPG";
import tutorial_image_7 from "../assets/tutorial_images/07.JPG";
import tutorial_image_8 from "../assets/tutorial_images/08.JPG";
import tutorial_image_9 from "../assets/tutorial_images/09.JPG";
import tutorial_image_10 from "../assets/tutorial_images/10.JPG";
import tutorial_image_11 from "../assets/tutorial_images/11.JPG";
import tutorial_image_13 from "../assets/tutorial_images/13.JPG";
import tutorial_image_15 from "../assets/tutorial_images/15.JPG";
import tutorial_image_16 from "../assets/tutorial_images/16.JPG";

export const tutorialData = [
    {
        image: tutorial_image_1,
        title: 'Step 1: Getting Started - Positioning and Diagnosis',
        text: "Find a comfortable spot where the camera can capture your entire body for accurate recognition. Once you're set, the image will appear in red. Don't worry; this is normal! The system is diagnosing your skeleton and joints. Just be patient, and we'll move on shortly. Ready? Let's dive in 😊👍",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_2,
        title: 'Step 2: Activating Skeleton Detection and Exercise Display',
        text: "Let's dive into the exciting part! Look in the right corner for an image. This image will guide you through the exact exercise you should perform. Get ready to mirror the displayed figure! Over in the left corner, you'll see text displaying your status. For instance, before you start, it might read 'Starting Exercise.' Time to get moving and make progress together! 🏋️‍♂️💪",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_4,
        title: 'TIP',
        text: "Stay on track with this handy feature! If you remain inactive in front of the camera for over 30 seconds, a pop-up reminder will appear, saying: 'You haven't started exercising yet. Would you like to continue or exit?' This is designed to keep you engaged and focused on your fitness journey. Whether you're pushing forward or taking a breather, the choice is yours. Remember, your progress is in your hands! 🏅💪",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_5,
        title: 'Step 3: Perfecting the Exercise with Precision',
        text: "Now, let's focus on executing the exercise correctly to reap the maximum benefits. When you align accurately with the given shape, your skeleton will turn green. This change in color means you're on the right track! Monitor the progress bar at the bottom right; once it displays 100 %, you'll know you've successfully completed the exercise. Well done! On the lower left, there's a timer counting down. Your challenge? Maintain the exercise for a full 10 seconds. Ensure you keep going until the timer hits zero. Push through and aim for excellence! You've got this! 🟢💯⏳💪",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_6,
        title: 'Step 4: Capturing Your Progress',
        text: "As you complete the exercise for the designated 10 seconds, a pop-up will appear, notifying you that your data is being recorded. Please be patient while we capture your valuable progress. It won't take long, and your efforts will be well-documented for your reference. Keep up the great work! 📈🎉",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_7,
        title: 'Step 5: Moving On to the Next Exercise',
        text: "Congratulations on successfully completing the exercise and registering your data! Soon after, a pop-up will appear, offering you three choices. The decision is in your hands, so take a moment to consider what's best for you. Let's keep the momentum going! 🚀💪 ",
        bullet: "\nRetry: Want to try the exercise again to enhance your performance? This option is for you.\nNext: Eager for a new challenge? Choose this to proceed to the subsequent exercise.\n Exit: If you feel like wrapping up for now, opt for this. Come back when you're recharged and ready for more!",
        extra: "",
    },
    {
        image: tutorial_image_8,
        title: 'Step 6: Seated Side Stretch - Engage Both Hands',
        text: "Dive into the second exercise, the 'Seated Side Stretch'. This exercise will engage both sides of your body, using your hands to deepen the stretch. Here's how to perform it:",
        bullet: "\nBegin by aligning yourself with the on-screen figure, using one hand as directed.\nHold the position for 10 seconds, ensuring proper form and alignment throughout.\nAfter the timer runs out, switch to the other side and repeat the stretch for another 10 seconds.",
        extra: "To support you during this exercise, there will be real-time feedback displayed at the top of the left page. This feedback aims to guide your movements, helping you achieve the correct posture and technique. Remember, consistency and correct execution are crucial to benefit fully from each stretch. Let's maximize this session by stretching both sides effectively, enhancing your flexibility and overall well-being!",
    },
    {
        image: tutorial_image_9,
        title: 'Tip',
        text: "If your exercise isn't spot-on, your skeleton might turn purple. This suggests some joints aren't aligned correctly, causing your progress bar score to dip, often to 90-95%.\nBut don't stress! Even if the exercise isn't perfect, we still acknowledge your effort. The timer keeps tracking your dedication, and you can monitor your progress. For extra guidance, check the upper left corner of the screen. It'll point out specific joints or angles needing adjustment, helping you refine your form.\nRemember: Every effort matters. With practice, you'll keep improving. Stay persistent, and your diligence will shine through! 🟪🏋️‍♀️💯",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_10,
        title: 'Tip',
        text: "If the exercise isn't done correctly or fully by the time the timer hits zero, the skeleton will turn red. This signals issues with your execution. You'll then receive feedback noting that the exercise wasn't completed.",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_11,
        title: "Step 7: Upward Hand Stretch - Reach for the Sky",
        text: "For our third exercise, closely follow the picture's guidance. Pay attention to the feedback and make sure your pose aligns properly. Together, let's reach for the sky! 🌟🙌",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_13,
        title: "Step 8: Triceps Stretch - Follow the Pose",
        text: "For our fourth exercise, we'll tackle the 'Triceps Stretch'. Focus on your triceps and maximize the benefits by mirroring the pose shown in the picture. Keep an eye on the feedback, and aim for the green indicator as a sign you're on track. Let's stretch those triceps and embrace the intensity! 💪🔵",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_15,
        title: "Step 9: Neck Stretch - Loosen Up Your Neck",
        text: "Welcome to the final exercise! This step is designed to relieve tension and enhance flexibility in your neck. Always use slow, controlled movements, and avoid forcing your neck into any discomfort. Revel in the feeling of relief and care you're giving to your neck muscles. Keep up the stellar work! 🧘‍♀️💆‍♂️",
        bullet: "",
        extra: "",
    },
    {
        image: tutorial_image_16,
        title: "The End - Celebrate Your Achievement!",
        text: "Congratulations on completing the exercises! You've made a meaningful stride towards enhancing your flexibility and overall well-being. Remember, consistency is the key to reaching your fitness aspirations. Keep up the impressive work! Revisit these exercises as desired or delve into other routines for varied workouts. Always be attuned to your body and provide it with the care it merits. Should you have questions or require further assistance, we're here to help.",
        bullet: "",
        extra: "",
    }
    // Add more tutorial data here as needed
];