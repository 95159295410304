import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  hyperlink: {
    fontSize: "0.875rem",
    marginRight: "15px",
    marginLeft: "15px",
    color: 'white'
  },
  copyright: {
    fontSize: "0.75rem",
    margin: "0px",
    marginRight: "20px",
    marginLeft: "20px",
    color: 'white'
  },
}));

function Footer(props) {
  const classes = useStyles();
  const largeScreen = true;

  return (
      <div
        style={{
          // marginRight: "20px",
        //   height: "100%",
          // float: "right",
          display: "flex",

          flexDirection: largeScreen? 'row': 'column-reverse',
          color: "white",
          alignItems: "center",
          justifyContent: largeScreen?"center":"space-around"
        }}
      >
        <div>
          <p className={classes.copyright}>© {new Date().getFullYear()} SmartyX GmbH </p>
        </div>
        <div>
          <a className={classes.hyperlink} href="https://smartyx.ai/imprint" rel="noreferrer">
            Imprint
          </a>
          <a className={classes.hyperlink} href="https://smartyx.ai/privacy" rel="noreferrer">
            Privacy
          </a>
          <a className={classes.hyperlink} href="https://smartyx.ai/contact" rel="noreferrer">
            Contact
          </a>
        </div>
      </div>
  );
}

export default Footer;