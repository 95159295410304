const DEBUG = false;
const BACKEND_HOST_URL = DEBUG ? "localhost:8000" : window.location.host;
const API_URL = `http${DEBUG ? "" : "s"}://${BACKEND_HOST_URL}/api/exercise/image_receiving/`;
const API_URL_CREATE_SESSION = `http${DEBUG ? "" : "s"}://${BACKEND_HOST_URL}/api/exercise/create_new_session/`;
const WEBSOCKET_URL = `ws${DEBUG ? "" : "s"}://${BACKEND_HOST_URL}/ws/detection/`;
const BATCH_SIZE = 5;
const SAVE_VIDEO = true;
const NOT_EXERCISING_TIMEOUT = 80;

export const exercise_config_variables = {
    DEBUG: DEBUG,
    BACKEND_HOST_URL: BACKEND_HOST_URL,
    API_URL: API_URL,
    WEBSOCKET_URL: WEBSOCKET_URL,
    BATCH_SIZE: BATCH_SIZE,
    SAVE_VIDEO: SAVE_VIDEO,
    NOT_EXERCISING_TIMEOUT: NOT_EXERCISING_TIMEOUT,
    API_URL_CREATE_SESSION:API_URL_CREATE_SESSION,
}
