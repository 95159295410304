import PropTypes from "prop-types";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

function ContinoueExercisingModal(props) {
  const { onContinue, onExit } = props;

  const handleOnContinue = () => {
    typeof onContinue === 'function' && onContinue();
  };

  const handleOnExit = () => {
    typeof onExit === 'function' && onExit();
  };

  return (
    <Dialog
      open={true}
      onClose={handleOnContinue}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
        You haven't started exercising yet. Would you like to continue or exit?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{color:'#00252e'}} autoFocus onClick={handleOnContinue}>
          Continue
        </Button>
        <Button sx={{color:'#00252e'}} autoFocus onClick={handleOnExit}>
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
ContinoueExercisingModal.prototype = {
  onContinue: PropTypes.func,
  onExit: PropTypes.func
}
export default ContinoueExercisingModal;