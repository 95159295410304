import React from 'react';
import PropTypes from 'prop-types';
import {Route, Navigate} from 'react-router-dom';

export default function RouteWrapper({element: Element, isPrivate, ...rest}) {
  const signed = true;
  /**
   * Navigate user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return <Navigate to="/"/>;
  }
  /**
   * Navigate user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  // if (!isPrivate && signed) {
  //   return <Navigate to="/"/>;
  //   // return <Navigate to="/dashboard"/>;
  // }
  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return Element;
}
RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
};
